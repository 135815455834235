exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-waves-js": () => import("./../../../src/pages/waves.js" /* webpackChunkName: "component---src-pages-waves-js" */),
  "component---src-templates-post-layout-js-content-file-path-src-posts-another-poast-mdx": () => import("./../../../src/templates/post-layout.js?__contentFilePath=/Users/andremolnar/www/repos/andremolnardotcom/src/posts/another-poast.mdx" /* webpackChunkName: "component---src-templates-post-layout-js-content-file-path-src-posts-another-poast-mdx" */),
  "component---src-templates-post-layout-js-content-file-path-src-posts-apple-coreml-mdx": () => import("./../../../src/templates/post-layout.js?__contentFilePath=/Users/andremolnar/www/repos/andremolnardotcom/src/posts/apple-coreml.mdx" /* webpackChunkName: "component---src-templates-post-layout-js-content-file-path-src-posts-apple-coreml-mdx" */),
  "component---src-templates-post-layout-js-content-file-path-src-posts-frontmattter-tagging-mdx": () => import("./../../../src/templates/post-layout.js?__contentFilePath=/Users/andremolnar/www/repos/andremolnardotcom/src/posts/frontmattter_tagging.mdx" /* webpackChunkName: "component---src-templates-post-layout-js-content-file-path-src-posts-frontmattter-tagging-mdx" */),
  "component---src-templates-post-layout-js-content-file-path-src-posts-ml-all-the-things-mdx": () => import("./../../../src/templates/post-layout.js?__contentFilePath=/Users/andremolnar/www/repos/andremolnardotcom/src/posts/ml-all-the-things.mdx" /* webpackChunkName: "component---src-templates-post-layout-js-content-file-path-src-posts-ml-all-the-things-mdx" */),
  "component---src-templates-post-layout-js-content-file-path-src-posts-speedy-people-detection-mdx": () => import("./../../../src/templates/post-layout.js?__contentFilePath=/Users/andremolnar/www/repos/andremolnardotcom/src/posts/speedy_people_detection.mdx" /* webpackChunkName: "component---src-templates-post-layout-js-content-file-path-src-posts-speedy-people-detection-mdx" */),
  "component---src-templates-post-layout-js-content-file-path-src-posts-test-mdx": () => import("./../../../src/templates/post-layout.js?__contentFilePath=/Users/andremolnar/www/repos/andremolnardotcom/src/posts/test.mdx" /* webpackChunkName: "component---src-templates-post-layout-js-content-file-path-src-posts-test-mdx" */)
}

